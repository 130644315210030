import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .section {
    > div + .padded-section {
      padding-top: 15px;
    }

    > .padded-section:last-of-type {
      padding-bottom: 0;
    }
  }

  .sessions {
    > div {
      h4 {
        margin-bottom: 10px;

        + p {
          button {
            cursor: pointer;
          }
        }
      }

      > p > span ~ button {
        cursor: pointer;
      }

      p + p {
        margin-top: 4px;
      }

      + div {
        margin-top: 40px;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    .section {
      > div + .padded-section {
        padding-top: 30px;
      }
    }

    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .breakout-sessions {
      > div {
        + div {
          margin-top: 60px;
        }
      }
    }
  }
`

const BreakoutSessions = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulReunionBreakoutSession(
        filter: { type: { eq: "Reunion" } }
        sort: { fields: [day, time, order, location], order: [ASC, ASC] }
      ) {
        edges {
          node {
            id
            day
            time
            title
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            location
            presenters {
              id
              name
            }
            bios {
              id
              name
              image {
                fixed(width: 170, quality: 60) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              body {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)

  const breakoutSessions = data.allContentfulReunionBreakoutSession.edges

  return (
    <Wrapper>
      <section className="section">
        <h3 style={{ marginBottom: '20px' }} className="headline--reunion center-align">Breakout Sessions</h3>
        <div>
          {breakoutSessions.map(session => (
            <div key={session.node.id} style={{ marginBottom: '30px', paddingLeft: '10px' }}>
              <h4 key={session.node.id} className="headline--small" style={{ margin: '0' }}>{session.node.title}</h4>
              <p style={{ marginTop: '4px' }}>
                <span className="semi-bold-weight">Presenter: </span>Coming Soon
              </p>
              <p style={{ marginTop: '4px' }} key={session.node.id}>
                <span className="semi-bold-weight">Description: </span>
                {session.node.description.childMarkdownRemark.rawMarkdownBody}
              </p>
            </div>
          ))}
        </div>
      </section>
    </Wrapper>
  )
}

export default BreakoutSessions
